const LAYOUT_TIMEOUT = 1250;
const BACK_BUTTON_TIMEOUT = LAYOUT_TIMEOUT / 3;
const SCROLL_TIMEOUT = 350; //ms
const CLOSE_MENU_TIMEOUT = 300; //ms == menu style property --> transition: transform 0.3s ease-in-out; 
const CLOSE_MENU_TIMEOUT_OFFSET = 80; //ms
const VIDEO_LAYOUT_ID = 0, CONTENT_LAYOUT_ID = 1;
const LOADING_SCROLLS = 15;
const LOADING_SCROLLS_TRACKPAD = 30;

const appHeight = () => document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
window.addEventListener('resize', function () {
  appHeight();
});
appHeight();

var lethargy;
if (sessionStorage.getItem('scrollAmount') != null) {
  lethargy = new Lethargy(7, 10, 0.15);
}

var currentLayout = VIDEO_LAYOUT_ID;
var scrollCounter = 0;
var trackPadTimer;
var hideMenuTimer;


var confirmScrollToVideo = false;
var scrollTimer;
var tlBrTimer;
var isFirstScrollDown = false;
var loadNextProject = false;
var lastActiveElement;

var layout = document.querySelector('.layout');
var contentLayout = document.querySelector('.content-layout');
var videoLayout = document.querySelector('.video-layout');
var openMenu = document.querySelector('.js-open-menu');
var closeMenuMobile = document.querySelector('.js-close-menu');
var backButton = document.querySelector('.js-back');
var topLeft = document.querySelector('.js-tl');
var bottomRight = document.querySelector('.js-br');
var projectWrapper = document.querySelector('.project-wrapper');
var fadeTransition = document.querySelector('.fade-transition');
var grid = document.querySelector('.grid');
var video = document.getElementById('video');
var menu = document.querySelector('.menu');
var loadnextpages = document.querySelectorAll('.loadnextpage');
var gridProjects = document.querySelectorAll('.grid-project');
var textBlocks = document.querySelectorAll('.project-section--text, .studio-section');
var nextProjectPreview = document.querySelector('.nextProjectPreview');
var sliders = document.querySelectorAll('project-section--slider');

sliders.forEach(slider => {
  slider.addEventListener();
});

if (nextProjectPreview) {
  //load image
  nextProjectPreview.classList.add('show');
  setTimeout(function () { nextProjectPreview.classList.remove('show'); }, 500);
}


if (openMenu) {
  openMenu.onclick = function () {
    document.body.classList.toggle('menu-active');
  }
}


if (closeMenuMobile) {
  closeMenuMobile.onclick = function () {
    document.body.classList.remove('menu-active');
  }
}


window.addEventListener('DOMContentLoaded', (event) => {
  document.body.classList.add('loading');

  if (document.getElementsByName('homegrid')[0]) {
    if (isTouchDevice()) {
      window.scrollTo(0, window.innerHeight);
    } else {
      scrollToContentLayout();
    }
  }

  setTimeout(function () {
    document.body.classList.remove('loading');
    fadeTransition.classList.remove('active');
    setTimeout(function () { fadeTransition.classList.add('hide'); }, 700);
    if (video && video.hasAttribute('src') && video.getAttribute('src') != "") video.play();
  }, LAYOUT_TIMEOUT);
});



//MOBILE
if (isTouchDevice()) {
  loadnextpages.forEach(element => {
    element.onclick = function (e) { loadNextPageMobile(e, element) };
  });

  document.querySelectorAll('.grid-project__img').forEach(function (img) {
    var src = img.getAttribute('src');

    if (!src.includes('vt-')) {
      src = src.replace('thumbnails/', 'thumbnails/vt-');
    }

    img.setAttribute('src', src);
  });

  if (projectWrapper) {
    // var ts;
    // layout.addEventListener('touchstart', (e) => {
    //   ts = e.changedTouches[0].clientY;
    // });

    layout.addEventListener('touchend', (e) => {
      // var te = e.changedTouches[0].clientY;
      // if (ts > te + 5) {
      //   manageOnProjectScrollEnd(e, true);
      // } else if (ts < te - 5) {
      //   console.log('up');
      // }

      if (window.scrollY < videoLayout.offsetHeight && contentLayout.offsetHeight < videoLayout.offsetHeight) {
        setTimeout(function () { openMenu.style.zIndex = "10"; }, 1500);
      }

    });

    document.querySelector('.js-mobile-nextproject').onclick = function (e) {
      //amaguem els elements que no han d'apareixer en el videoLayout
      topLeft.style.zIndex = -1;
      if (bottomRight) bottomRight.style.zIndex = -1;

      window.scrollTo(0, layout.offsetHeight);
      nextProjectPreview.classList.add('show');
      document.body.classList.add('loading');
      layout.style.transform = "translateY(-" + window.innerHeight + "px)";
      setTimeout(function () {
        loadNextPage();
        window.location.replace(projectWrapper.dataset.nextproject);
      }, LAYOUT_TIMEOUT + 300);
    };
  }


} else {
  //DESKTOP - MOUSE

  /**
   * AFEGIM SCROLL HORIZONTAL AL CONTENT LAYOUT
   * 
   */


  if (layout) {
    // IE9, Chrome, Safari, Opera
    layout.onmousewheel = function (e) { manageLayoutsTransition(detectMouseWheelDirection(e)); }
    // Firefox
    layout.addEventListener("wheel", function (e) { manageLayoutsTransition(detectMouseWheelDirection(e)); }, false);

    videoLayout.onclick = scrollToContentLayout;
    videoLayout.onmousewheel = function (e) { detectTrackPad(e); }
    // Firefox
    videoLayout.addEventListener("wheel", function (e) { detectTrackPad(e) });
  }

  if (contentLayout.addEventListener) {
    // IE9, Chrome, Safari, Opera
    contentLayout.addEventListener("mousewheel", e => manageHorizontalScroll(e, getScrollAmount()));

    // Firefox
    contentLayout.addEventListener("wheel", e => manageHorizontalScroll(e, getScrollAmount()), false);
  } else {
    // IE 6/7/8
    contentLayout.attachEvent("onmousewheel", e => manageHorizontalScroll(e, getScrollAmount()));
  }



  if (projectWrapper) {
    // IE9, Chrome, Safari, Opera
    contentLayout.onmousewheel = function (e) { manageOnProjectScrollEnd(e, false); }
    // Firefox
    contentLayout.addEventListener("wheel", function (e) { manageOnProjectScrollEnd(e, false); }, false);

    projectWrapper.style.overflow = "unset";
  }

  loadnextpages.forEach(element => {
    element.onclick = loadNextPage;
  });
}

if(!isTouchDevice()){
  textBlocks.forEach((textBlockElement) => {
    manageTextBlock(textBlockElement);
  });
  
  window.addEventListener('resize', function () {
    textBlocks.forEach((textBlockElement) => {
      manageTextBlock(textBlockElement);
    });
  });
}



function manageOnProjectScrollEnd(e, mobile) {

  // if (mobile) {
  //   if (window.scrollY >= contentLayout.offsetHeight - 100) {
  //     scrollCounter++;
  //     clearTimeout(scrollTimer);

  //     if (scrollCounter == 1) {
  //       nextProjectPreview.classList.add('show');
  //       document.body.classList.add('loading');
  //       layout.style.transform = "translateY(-400px)";
  //       //amaguem els elements que no han d'apareixer en el videoLayout
  //       topLeft.style.zIndex = -1;
  //       if (bottomRight) bottomRight.style.zIndex = -1;

  //       setTimeout(function () {
  //         layout.style.transform = "translateY(0%)";
  //         nextProjectTimer = setTimeout(function () {
  //           topLeft.style.zIndex = 2;
  //           if (bottomRight) bottomRight.style.zIndex = 2;
  //           document.body.classList.remove('loading');
  //           nextProjectPreview.classList.remove('show');
  //         }, LAYOUT_TIMEOUT);
  //       }, 300);

  //     } else if (scrollCounter > 1) {
  //       clearTimeout(nextProjectTimer);
  //       document.body.classList.add('loading');
  //       layout.style.transform = "translateY(-" + nextProjectPreview.offsetHeight + "px)";

  //       setTimeout(function () {
  //         loadNextPage();
  //         window.location.replace(projectWrapper.dataset.nextproject);
  //       }, LAYOUT_TIMEOUT + 300);

  //     }

  //     scrollTimer = setTimeout(function () {
  //       scrollCounter = 0;
  //     }, 1500);
  //   }

  // } else {
  var direction = detectMouseWheelDirection(e);

  // var lastProjectSection = projectWrapper.lastElementChild;
  var lastProjectSection = projectWrapper.children[projectWrapper.childElementCount - 2];

  var pageEndX = (lastProjectSection.offsetLeft + lastProjectSection.offsetWidth) - videoLayout.offsetWidth;


  var hasNotInertia;
  lethargy == undefined ? hasNotInertia = -1 : hasNotInertia = lethargy.check(e);

  if (contentLayout.scrollLeft >= pageEndX && direction == "down" && hasNotInertia != false) {

    //amaguem els elements que no han d'apareixer per sobre
    topLeft.style.zIndex = -1;
    if (bottomRight) bottomRight.style.zIndex = -1;

    clearTimeout(scrollTimer);
    clearTimeout(hideMenuTimer);

    scrollCounter++;

    var scrollMultip = scrollCounter * 3;
    var requiredScrollCount = LOADING_SCROLLS;

    if (sessionStorage.getItem('scrollAmount') != null) {
      scrollMultip /= 2;
      requiredScrollCount = LOADING_SCROLLS_TRACKPAD;
    }

    //timeout per a fer el scroll mes smooth
    setTimeout(function () { layout.style.transform = "translateX(" + (-100 - scrollMultip) + "%)"; }, 70);


    if (scrollCounter >= requiredScrollCount) {
      //si el nombre de scrolls sobrepassa al requerit, redirigim al next project
      scrollTimer = setTimeout(function () {
        layout.style.transform = "translateX(-200%)";
        setTimeout(function () {
          scrollCounter = 0;
          loadNextPage();
          window.location.assign(projectWrapper.dataset.nextproject);
        }, 500);
      }, 300);

    } else if (scrollCounter < requiredScrollCount) {
      //si el nombre de scrolls no arriba al requerit, amaguem el next project
      scrollTimer = setTimeout(function () {
        layout.style.transform = "translateX(-100%)";
        scrollCounter = 0;

        hideMenuTimer = setTimeout(function () {
          topLeft.style.zIndex = 2;
          if (bottomRight) bottomRight.style.zIndex = 2;
        }, 1000);
      }, 400);
    }
  }

  // }
}


function manageLayoutsTransition(direction) {

  //scroll to contentLayout
  if (currentLayout == VIDEO_LAYOUT_ID && videoLayout.scrollLeft == 0 && direction == "down") {
    scrollToContentLayout();
  }

  //scroll dins el contentLayout
  if (currentLayout == CONTENT_LAYOUT_ID && contentLayout.scrollLeft > 0) {
    isFirstScrollDown = false;
    confirmScrollToVideo = false;

    //mostra indicador feedback al fer scroll en x=0
    if (backButton.classList.contains('back-button--show')) {
      backButton.classList.remove('back-button--show');
    }
  }

  //scroll to videoLayout
  if (currentLayout == CONTENT_LAYOUT_ID && contentLayout.scrollLeft == 0 && direction == "up") {
    scrollToVideoLayout();
  }
}




function scrollToContentLayout() {
  layout.style.transform = "translateX(-100%)";
  if (layout.classList.contains('fast-transition')) layout.classList.remove('fast-transition');
  setTimeout(function () { layout.classList.add('fast-transition'); }, LAYOUT_TIMEOUT);


  currentLayout = CONTENT_LAYOUT_ID;
  if (!isFirstScrollDown) isFirstScrollDown = true;

  //mostrem els elements que han d'apareixer en el contentLayout
  tlBrTimer = setTimeout(function () {
    topLeft.style.zIndex = 2;
    if (bottomRight) bottomRight.style.zIndex = 2;
  }, LAYOUT_TIMEOUT);
}

function scrollToVideoLayout() {
  if (confirmScrollToVideo || isFirstScrollDown) {

    var timeout = 0;

    //tanca el menu en cas que estigui obert
    if (isMenuOpen()) {
      closeMenu();
      //ajustem el timeout per a que sigui més fluid
      timeout = CLOSE_MENU_TIMEOUT + CLOSE_MENU_TIMEOUT_OFFSET;
    }

    //acaba la animacio en cas que estigui activa
    if (fadeTransition.classList.contains('active')) {
      fadeTransition.classList.remove('active');
      contentLayout.classList.remove('hide');
    }

    //realitza la transicio
    setTimeout(function () {
      isFirstScrollDown = false;
      confirmScrollToVideo = false;

      //eliminem el timeout dels elements menu i bttomRight
      clearTimeout(tlBrTimer);

      //amagarem la fletxa del contentLayout
      setTimeout(function () { backButton.classList.remove('back-button--show') }, BACK_BUTTON_TIMEOUT);

      layout.classList.remove('fast-transition');
      //translate al videoLayout
      layout.style.transform = "translateX(0)";
      currentLayout = VIDEO_LAYOUT_ID;

      //amaguem els elements que no han d'apareixer en el videoLayout
      topLeft.style.zIndex = -1;
      if (bottomRight) bottomRight.style.zIndex = -1;

    }, timeout);

  } else {
    //si no s'ha executat el timeout anterior, no cal que s'executi ara
    clearTimeout(scrollTimer);

    setTimeout(function () {
      layout.style.transform = "translateX(-92%)";
      setTimeout(function () { layout.style.transform = "translateX(-100%)" }, 300);
    }, 100);

    //si d'aqui 180ms no fa scroll, hem assumit que l'usuari ja ha fet tot el scroll necessari per tornar a x=0, en el seguent scroll li permetra tornar al video
    scrollTimer = setTimeout(function () {
      backButton.classList.add('back-button--show');
      confirmScrollToVideo = true;
    }, SCROLL_TIMEOUT);

  }
}

function manageHorizontalScroll(e, scrollAmount) {
  if (sessionStorage.getItem('scrollAmount') == null) {
    scrollHorizontally(e, scrollAmount);
  } else {
    scrollHorizontally(e, scrollAmount * Math.abs(e.deltaY / 10));
  }
}


function scrollHorizontally(e, scrollQ) {
  e = window.event || e;
  var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.deltaY || -e.detail)));
  contentLayout.scrollLeft -= (delta * scrollQ);
  e.preventDefault();
}



function detectMouseWheelDirection(e) {
  var delta = null, direction = false;

  if (!e) { // if the event is not provided, we get it from the window object
    e = window.event;
  }
  if (e.wheelDelta) { // will work in most cases
    delta = e.wheelDelta / 60;
  } else if (e.deltaY) {
    delta = -e.deltaY / 60;
  }
  else if (e.detail) { // fallback for Firefox
    delta = -e.detail / 2;
  }
  if (delta !== null) {
    direction = delta > 0 ? 'up' : 'down';
  }

  return direction;
}

function detectTrackPad(e) {
  if (sessionStorage.getItem('scrollAmount') == null) {
    if (Math.abs(e.deltaX) > 0) {
      sessionStorage.setItem('scrollAmount', '15')
    }
  }
}

function getScrollAmount() {
  let scrollAmount = sessionStorage.getItem('scrollAmount');
  if (scrollAmount == null) {
    scrollAmount = 120;
  } else {
    scrollAmount = parseInt(scrollAmount);
  }

  return scrollAmount;
}

function manageTextBlock(textBlockElement) {
  textBlockElement.style.width = "400px";
  if (textBlockElement.scrollWidth > textBlockElement.clientWidth) {
    textBlockElement.style.width = (textBlockElement.scrollWidth + 100) + "px";
  }
}

function isScrollableDiv(el) {
  return el.scrollHeight - 52 > el.clientHeight;
}


function loadNextPageMobile(event, element) {
  if (element.classList.contains('grid-project')) {
    if (lastActiveElement == element) {
      loadNextPage();
    } else {
      lastActiveElement = element;
      event.preventDefault();
    }
  }
}

function loadNextPage() {
  if (isMenuOpen) closeMenu();
  document.body.classList.add('loading');
  fadeTransition.classList.remove('hide');
  fadeTransition.classList.add('active');
}

function isTouchDevice() {
  return ('ontouchstart' in window);
}

function isMenuOpen() {
  return document.body.classList.contains('menu-active');
}

function closeMenu() {
  if (isMenuOpen()) {
    document.body.classList.remove('menu-active');
    openMenu.innerHTML = "MENU";
  }
}